import React from 'react';

import { ErrorMessage, Field } from 'formik';

import Checkbox from '@components/common/Checkbox/Checkbox';
import InputErrorMessage from '@components/common/Formik/InputErrorMessage/InputErrorMessage';

type FormikCheckBoxProps = {
    name: string;
    onCheckboxChange?: (boolean) => void;
    customClass?: string;
    customColor?: boolean;
    customStyle?: any;
    defaultStyle?: any;
    children?: any;
};

const FormikCheckBox: React.FC<FormikCheckBoxProps> = ({
    name,
    onCheckboxChange,
    customClass,
    customColor,
    ...props
}: FormikCheckBoxProps) => {
    return (
        <div className={customClass || ''}>
            <Field name={name}>
                {({ field }) => (
                    <Checkbox {...props} {...field} customColor={customColor} onCheckboxChange={onCheckboxChange} />
                )}
            </Field>
            <ErrorMessage name={name} render={(msg) => <InputErrorMessage name={name} messageCode={msg} />} />
        </div>
    );
};

export default FormikCheckBox;
