import React from 'react';

import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import Text from '@components/common/Text/Text';

import css from './InputErrorMessage.module.scss';

type PropsType = {
    messageCode: string;
    name: string;
    customClass?: string;
    paramKey?: string;
    paramValue?: any;
};

const InputErrorMessage: React.FC<PropsType> = ({
    messageCode,
    name,
    customClass,
    paramKey,
    paramValue,
}: PropsType) => {
    const { t } = useTranslateLpc('common');
    return (
        <div className={`${customClass || css.wrapper}`}>
            <Text variant="caption_02" color="ui-error">
                {messageCode === 'REQUIRED'
                    ? t(`errors.${messageCode}`)
                    : t(`errors.${messageCode}_${name}`, { [paramKey]: paramValue })}
            </Text>
        </div>
    );
};

export default InputErrorMessage;
