import { ReactOptions, useTranslate } from '@tolgee/react';

const useTranslateLpc = (namespaces: string[] | string, options?: ReactOptions) => {
    if (typeof namespaces === 'string') {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useTranslate(namespaces, options);
    } else {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const translateInstance = useTranslate(namespaces, options);
        const combinedTranslateFunction = (key, ...args) => {
            for (const namespace of namespaces) {
                const translation = translateInstance.t(key, { ...args[0], ns: namespace });
                if (translation.replace(/^[\s‌‍]+|[\s‌‍]+$/g, '') !== key) {
                    return translation;
                }
            }
            return key;
        };

        return { t: combinedTranslateFunction };
    }
};

export default useTranslateLpc;
