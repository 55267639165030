import React from 'react';

import { Field, ErrorMessage } from 'formik';

import InputErrorMessage from '@components/common/Formik/InputErrorMessage/InputErrorMessage';
import InputField from '@components/common/InputField/InputField';

const valid = (touched, errors, name) => touched[name] && !errors[name];
const error = (touched, errors, name) => touched[name] && errors[name];
type FormikInputFieldProps = {
    id?: string;
    name: string;
    type: string;
    label?: string;
    placeholder?: string;
    key?: string;
    customStyle?: string;
    customCssStyle?: any;
    customLabelStyle?: string;
    disabled?: boolean;
    iconPosition?: 'left' | 'right';
    icon?: string;
    isAmount?: boolean;
    height?: string;
    padding?: string;
    maxLength?: number;
    isFloatingLabel?: boolean;
    isActivatedLabel?: boolean;
    autoComplete?: boolean;
    autoFocus?: boolean;
    onHandleChange?: React.EventHandler<any>;
    onHandleBlur?: React.EventHandler<any>;
};

const FormikInputField: React.FC<FormikInputFieldProps> = ({
    name,
    disabled,
    isAmount,
    onHandleChange,
    onHandleBlur,
    customStyle,
    customCssStyle,
    isFloatingLabel,
    maxLength,
    autoFocus,
    isActivatedLabel,
    autoComplete,
    ...props
}: FormikInputFieldProps) => {
    return (
        <Field name={name}>
            {({ field, form: { touched, errors } }) => {
                return (
                    <div>
                        <InputField
                            error={error(touched, errors, name)}
                            valid={valid(touched, errors, name)}
                            disabled={disabled}
                            autoFocus={autoFocus}
                            isAmount={isAmount}
                            isActivatedLabel={isActivatedLabel}
                            onHandleChange={onHandleChange}
                            onHandleBlur={onHandleBlur}
                            customStyle={customStyle}
                            customCssStyle={customCssStyle}
                            inputMaxLength={maxLength}
                            isFloatingLabel={isFloatingLabel}
                            inputAutoComplete={autoComplete}
                            {...props}
                            {...field}
                        />

                        {!disabled && (
                            <ErrorMessage
                                name={name}
                                render={(msg) => <InputErrorMessage name={name} messageCode={msg} />}
                            />
                        )}
                    </div>
                );
            }}
        </Field>
    );
};

export default FormikInputField;
