import React from 'react';

import css from './Spinner.module.scss';

type SpinnerProps = {
    customClass?: string;
};

const Spinner = ({ customClass }: SpinnerProps) => {
    return (
        <>
            <div className={`${css.wave_spinner} ${customClass || ''}`}>
                <img
                    src="/front-static/images/home/sphere-blue.png"
                    alt="Yellow Dot"
                    className={`${css.dot} ${css.blue}`}
                />
                <img
                    src="/front-static/images/home/sphere-yellow.png"
                    alt="Pink Dot"
                    className={`${css.dot} ${css.yellow}`}
                />
                <img
                    src="/front-static/images/home/sphere-pink.png"
                    alt="Blue Dot"
                    className={`${css.dot} ${css.pink}`}
                />
            </div>
        </>
    );
};

export default Spinner;
