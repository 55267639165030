import React from 'react';

import css from './Checkbox.module.scss';
import Text from '../Text/Text';

interface CheckboxProps {
    children?: React.ReactNode;
    disabled?: boolean;
    error?: boolean;
    success?: boolean;
    onChange: any;
    value: any;
    name: string;
    customColor?: boolean;
    customClass?: string;
    onCheckboxChange?: (boolean) => void;
    customStyle?: any;
    defaultStyle?: any;
}

const Checkbox = ({
    children,
    disabled,
    error,
    success,
    onChange,
    value,
    name,
    onCheckboxChange,
    customColor,
    customClass,
    customStyle,
    defaultStyle,
}: CheckboxProps) => {
    const checked = value;
    const checkmarkStyle = () => {
        let className = css.checkmark;
        checked && customColor && (className += ' ' + css.checkmark__checked__customColor);
        checked && !customColor && (className += ' ' + css.checkmark__checked);
        checked && success && (className += ' ' + css.checkmark__checked__success);
        checked && error && (className += ' ' + css.checkmark__checked__error);
        error && (className += ' ' + css.checkmark__error);
        disabled && (className += ' ' + css.checkmark__disabled);
        return className;
    };

    return (
        <label className={`${css.container} ${disabled && css.container__disabled}`}>
            <input
                onChange={(e) => {
                    onChange(e);
                    onCheckboxChange && onCheckboxChange(value);
                }}
                name={name}
                value={value}
                checked={value}
                type="checkbox"
                disabled={disabled}
            />
            <span
                className={`${checkmarkStyle()} ${customClass || ''}`}
                style={checked && customStyle ? customStyle : defaultStyle || null}
            />
            <Text variant="button_small" color={disabled ? 'txt-secondary' : 'txt-primary'}>
                {children}
            </Text>
        </label>
    );
};

export default Checkbox;
